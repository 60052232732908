import React from 'react';

import insta1 from '../assets/images/instagram/insta1.jpg';
import insta2 from '../assets/images/instagram/insta2.jpg';
import insta3 from '../assets/images/instagram/insta3.jpg';
import insta4 from '../assets/images/instagram/insta4.jpg';
import insta5 from '../assets/images/instagram/insta5.jpg';
import insta6 from '../assets/images/instagram/insta6.jpg';
import insta7 from '../assets/images/instagram/insta7.jpg';
import insta8 from '../assets/images/instagram/insta8.jpg';

const Instagram = () => (
  <section className=" instagram-images media-page">
    <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
      <img src={insta2} alt="Audio Vultures Party Band Bristol" />
    </a>
    <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
      <img src={insta1} alt="Audio Vultures Wedding Band Bristol" />
    </a>
    <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
      <img src={insta3} alt="Audio Vultures Wedding Band Bristol" />
    </a>{' '}
    <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
      <img src={insta4} alt="Audio Vultures Function Band Bristol" />
    </a>
    <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
      <img src={insta6} alt="Audio Vultures Party Band Bristol" />
    </a>
    <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
      <img src={insta5} alt="Audio Vultures Wedding Band Bristol" />
    </a>
    <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
      <img src={insta7} alt="Audio Vultures Wedding Band Bristol" />
    </a>{' '}
    <a href="https://www.instagram.com/audiovultures/" target="_blank" rel="noreferrer">
      <img src={insta8} alt="Audio Vultures Function Band Bristol" />
    </a>
  </section>
);

export default Instagram;
