import React from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import Instagram from '../components/Instagram';
import Link from 'gatsby-link';
import Carousel, { Modal, ModalGateway } from 'react-images';
import AcousticBanner from '../components/AcousticBanner';

import Layout from '../components/Layout';

import audio_vultures_party_band from '../assets/images/media/audio_vultures_party_band.jpg';
import audio_vultures_party_band_thumb from '../assets/images/media/audio_vultures_party_band_thumb.jpg';
import audio_vultures_covers_band from '../assets/images/media/audio_vultures_covers_band.jpg';
import audio_vultures_covers_band_thumb from '../assets/images/media/audio_vultures_covers_band_thumb.jpg';
import audio_vultures_wedding_band from '../assets/images/media/audio_vultures_wedding_band.jpg';
import audio_vultures_wedding_band_thumb from '../assets/images/media/audio_vultures_wedding_band_thumb.jpg';
import audio_vultures_function_band_bristol from '../assets/images/media/audio_vultures_function_band_bristol.jpg';
import audio_vultures_function_band_bristol_thumb from '../assets/images/media/audio_vultures_function_band_bristol_thumb.jpg';
import audio_vultures_media_logo from '../assets/images/media/audio_vultures_media_logo.jpg';
import audio_vultures_media_logo_thumb from '../assets/images/media/audio_vultures_media_logo_thumb.jpg';
import av1 from '../assets/images/media/AV1.jpg';
import av1_thumb from '../assets/images/media/AV1-thumb.jpg';
import av2 from '../assets/images/media/AV2.jpg';
import av2_thumb from '../assets/images/media/AV2-thumb.jpg';
import av3 from '../assets/images/media/AV3.jpg';
import av3_thumb from '../assets/images/media/AV3-thumb.jpg';
import av4 from '../assets/images/media/AV4.jpg';
import av4_thumb from '../assets/images/media/AV4-thumb.jpg';
import av5 from '../assets/images/media/AV5.jpg';
import av5_thumb from '../assets/images/media/AV5-thumb.jpg';
import av7 from '../assets/images/media/AV7.jpg';
import av7_thumb from '../assets/images/media/AV7-thumb.jpg';
import av8 from '../assets/images/media/AV8.jpg';
import av8_thumb from '../assets/images/media/AV8-thumb.jpg';
import av9 from '../assets/images/media/AV9.jpg';
import av9_thumb from '../assets/images/media/AV9.jpg';
import av10 from '../assets/images/media/AV10.jpg';
import av10_thumb from '../assets/images/media/AV10.jpg';
import live1_thumb from '../assets/images/media/live_1_thumb.jpg';
import live2_thumb from '../assets/images/media/live_2_thumb.jpg';
import live3_thumb from '../assets/images/media/live_3_thumb.jpg';
import live4_thumb from '../assets/images/media/live_4_thumb.jpg';
import live5_thumb from '../assets/images/media/live_5_thumb.jpg';
import live6_thumb from '../assets/images/media/live_6_thumb.jpg';
import live1 from '../assets/images/media/audio_vultures_live_1.jpg';
import live2 from '../assets/images/media/audio_vultures_live_2.jpg';
import live3 from '../assets/images/media/audio_vultures_live_3.jpg';
import live4 from '../assets/images/media/audio_vultures_live_4.jpg';
import live5 from '../assets/images/media/audio_vultures_live_5.jpg';
import live6 from '../assets/images/media/audio_vultures_live_6.jpg';

const DEFAULT_IMAGES = [
  {
    id: '1',
    src: audio_vultures_party_band,
    thumbnail: audio_vultures_party_band_thumb,
    caption: '',
    description: '',
  },
  {
    id: '2',
    src: audio_vultures_wedding_band,
    thumbnail: audio_vultures_wedding_band_thumb,
    caption: '',
    description: '',
  },
  {
    id: '3',
    src: audio_vultures_function_band_bristol,
    thumbnail: audio_vultures_function_band_bristol_thumb,
    caption: '',
    description: '',
  },
  {
    id: '4',
    src: audio_vultures_covers_band,
    thumbnail: audio_vultures_covers_band_thumb,
    caption: '',
    description: '',
  },

  {
    id: '7',
    src: av1,
    thumbnail: av1_thumb,
    caption: '',
    description: '',
  },
  {
    id: '8',
    src: av2,
    thumbnail: av2_thumb,
    caption: '',
    description: '',
  },
  {
    id: '9',
    src: av3,
    thumbnail: av3_thumb,
    caption: '',
    description: '',
  },
  {
    id: '10',
    src: av4,
    thumbnail: av4_thumb,
    caption: '',
    description: '',
  },
  {
    id: '11',
    src: av5,
    thumbnail: av5_thumb,
    caption: '',
    description: '',
  },

  {
    id: '17',
    src: live1,
    thumbnail: live1_thumb,
    caption: '',
    description: '',
  },
  {
    id: '18',
    src: live2,
    thumbnail: live2_thumb,
    caption: '',
    description: '',
  },
  {
    id: '19',
    src: live3,
    thumbnail: live3_thumb,
    caption: '',
    description: '',
  },
  {
    id: '20',
    src: live4,
    thumbnail: live4_thumb,
    caption: '',
    description: '',
  },
  {
    id: '21',
    src: live5,
    thumbnail: live5_thumb,
    caption: '',
    description: '',
  },
  {
    id: '22',
    src: live6,
    thumbnail: live6_thumb,
    caption: '',
    description: '',
  },
  {
    id: '12',
    src: av7,
    thumbnail: av7_thumb,
    caption: '',
    description: '',
  },
  {
    id: '13',
    src: av8,
    thumbnail: av8_thumb,
    caption: '',
    description: '',
  },
  {
    id: '14',
    src: av9,
    thumbnail: av9_thumb,
    caption: '',
    description: '',
  },
  {
    id: '15',
    src: av10,
    thumbnail: av10_thumb,
    caption: '',
    description: '',
  },
  {
    id: '16',
    src: audio_vultures_media_logo,
    thumbnail: audio_vultures_media_logo_thumb,
    caption: '',
    description: '',
  },
];

class MediaTemplate extends React.Component {
  state = { selectedIndex: 0, lightboxIsOpen: false };

  toggleLightbox = (selectedIndex) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  render() {
    const { selectedIndex, lightboxIsOpen } = this.state;

    const gutter = 2;

    const Gallery = (props) => (
      <div
        css={{
          overflow: 'hidden',
          marginLeft: -gutter,
          marginRight: -gutter,
        }}
        {...props}
      />
    );

    const Image = (props) => (
      <div
        css={{
          backgroundColor: '#eee',
          boxSizing: 'border-box',
          float: 'left',
          margin: gutter,
          overflow: 'hidden',
          paddingBottom: '16%',
          position: 'relative',
          width: `calc(25% - ${gutter * 2}px)`,

          ':hover': {
            opacity: 0.9,
          },
        }}
        {...props}
      />
    );

    return (
      <Layout>
        <Helmet>
          <title>Covers Band Bristol, Hire a Band Bath - Audio Vultures Media</title>
          <meta
            name="description"
            content="Media page featuring photos and videos of Bristol covers band Audio Vultures. See why we are the premier choice to hire a band in Bath, surrounding areas and nationwide."
          />
        </Helmet>

        <div id="main" className="alt">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h1>Audio Vultures Covers Band Media, Bristol </h1>
              </header>
              <div className="row">
                <div className="12u">
                  <p>
                    Audio Vultures are a covers band available for hire in Bristol, Bath and
                    nationwide. You can view our videos, photos and Instagram feed below. We are the
                    premier band for hire in the Bristol area - <a href="#contact">contact us</a>{' '}
                    today for a no obligation quote!
                  </p>
                </div>
              </div>
              <h2>Video</h2>
              <div id="video" className="12u 12u$(medium)">
                <div className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://youtu.be/rfdDUb1XIaM"
                    width="100%"
                    height="100%"
                  />
                </div>
                <br />
                <div className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://youtu.be/_cs6U8k0ob8"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
              <hr className="major" />
              <h2>Photos</h2>
              <Gallery>
                <div className="row">
                  {DEFAULT_IMAGES.map(({ caption, id, thumbnail }, j) => (
                    <article className="6u 12u$(xsmall) work-item" key={id}>
                      <span className="image fit thumb">
                        <Image onClick={() => this.toggleLightbox(j)} key={id}>
                          <img
                            alt={caption}
                            src={thumbnail}
                            css={{
                              cursor: 'pointer',
                              position: 'absolute',
                              // maxWidth: '100%'
                            }}
                          />
                        </Image>
                      </span>
                    </article>
                  ))}
                </div>
              </Gallery>
              <p>
                Live photos by{' '}
                <a
                  href="https://jodiehurdphotography.com/"
                  target="_blank"
                  rel="nofollow noopener noreferrer">
                  Jodie Hurd Photography &copy; 2021
                </a>
              </p>
              <p>
                Video stills courtesy of{' '}
                <a
                  href="https://jensenfilms.co.uk/"
                  target="_blank"
                  rel="nofollow noopener noreferrer">
                  Jensen Films &copy; 2021
                </a>
              </p>
              <ModalGateway>
                {lightboxIsOpen ? (
                  <Modal onClose={this.toggleLightbox}>
                    <Carousel
                      views={DEFAULT_IMAGES}
                      currentIndex={selectedIndex}
                      frameProps={{ autoSize: 'height' }}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
              <hr className="major" />
              <ModalGateway>
                {lightboxIsOpen ? (
                  <Modal onClose={this.toggleLightbox}>
                    <Carousel
                      views={DEFAULT_IMAGES}
                      currentIndex={selectedIndex}
                      frameProps={{ autoSize: 'height' }}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
              <h2>Instagram Feed</h2>
              <Instagram />
              <h2>Hire a Band, Bath</h2>
              <div className="row">
                <div className="12u">
                  <p>
                    Looking to hire a band in Bath, Bristol and nationwide? We offer great quotes
                    for events of all types from private functions, to weddings, birthdays and more.
                    We have a great repertoire of cover songs, and also offer a DJ option for your
                    event!
                  </p>

                  <p>
                    Audio Vultures are dedicated to creating an energetic atmosphere and filling the
                    dancefloor with hits from across all decades! We have a complete 2500 watt PA
                    and lighting rig; further details are available on{' '}
                    <Link to="/frequently-asked-questions" className="link primary">
                      our FAQ page.
                    </Link>
                  </p>
                  <p>
                    Promo photos by{' '}
                    <a
                      href="https://www.facebook.com/stephennormanphoto/"
                      target="_blank"
                      rel="nofollow noopener noreferrer">
                      Stephen Norman Photography &copy; 2020
                    </a>
                    <br />
                    Live Instagram photos by{' '}
                    <a
                      href="https://www.jamieousbyphotography.com/"
                      target="_blank"
                      rel="nofollow noopener noreferrer">
                      Jamie Ousby Photography &copy; 2019
                    </a>
                    <br />
                    Live photos by{' '}
                    <a
                      href="https://jodiehurdphotography.com/"
                      target="_blank"
                      rel="nofollow noopener noreferrer">
                      Jodie Hurd Photography &copy; 2021
                    </a>
                  </p>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </section>
        </div>
        <AcousticBanner />
      </Layout>
    );
  }
}

export default MediaTemplate;
